import * as React from 'react';
import { FaChevronRight } from 'react-icons/fa';

import { styled } from 'stitches.config';
import Star from '@assets/icons/star.svg';

interface ReviewListItemProps {
  username: string;
  rate: number;
  content: string;
  game: string;
  link: string;
}

export function ReviewListItem({
  username,
  rate,
  content,
  game,
  link,
}: ReviewListItemProps) {
  return (
    <Container>
      <TitleInfo>
        <Username>{username}</Username>
        <Rate>
          {Array.from({ length: rate }).map((item, index) => (
            <img key={index} draggable={false} src={Star} alt="별점 이미지" />
          ))}
        </Rate>
      </TitleInfo>
      <Content>{content}</Content>
      <Link href={link} draggable={false}>
        {game} <FaChevronRight size={16} />
      </Link>
    </Container>
  );
}

const Container = styled('div', {
  width: '400px',
  padding: '30px',
  borderRadius: '15px',
  boxShadow: '0 3px 6px 0 rgba(0, 0, 0, .16)',
  backgroundColor: '#fff',

  '&:nth-child(odd)': {
    marginBottom: '50px',
  },
  '&:nth-child(even)': {
    marginTop: '50px',
  },

  '@bpMax991': {
    width: '380px',
  },
  '@bpMax767': {
    width: '340px',
  },
  '@bpMax575': {
    width: '300px',
  },
  '@bpMax420': {
    width: '265px',
  },

  '& *': {
    userSelect: 'none',
  },
});

const TitleInfo = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginBottom: '20px',
});

const Username = styled('h2', {
  fontSize: '20px',
  fontWeight: 700,
});

const Rate = styled('div', {
  wordWrap: 'break-word',
  flexShrink: 1,
});

const Content = styled('p', {
  whiteSpace: 'pre-line',
  lineHeight: 1.8,
  marginBottom: '30px',
  height: '150px',
  overflow: 'hidden',

  '@bpMax575': {
    fontSize: '14px',
  },
});

const Link = styled('a', {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  color: '#c869ff',

  '&:hover': {
    textDecoration: 'underline',
  },
});
