/**
 * window.innerWidth 값에 따른 게임 목록 요소 너비 반환
 * @param currentWidth 현재 window.innerWidth 값
 */
export const calculateGameItemWidth = (currentWidth: number) => {
  const screenWidthToItemWidth = {
    0: 265,
    420: 300,
    575: 360,
    767: 410,
    1200: 420,
  };

  return (
    Object.entries(screenWidthToItemWidth)
      .reverse()
      .find(([screen]) => currentWidth > parseInt(screen))?.[1] || 265
  );
};

/**
 * window.innerWidth 값에 따른 리뷰 목록 요소 너비 반환
 * @param currentWidth 현재 window.innerWidth 값
 */
export const calculateReviewItemWidth = (currentWidth: number) => {
  const screenWidthToItemWidth = {
    0: 265,
    420: 300,
    575: 340,
    767: 380,
    990: 400,
  };

  return (
    Object.entries(screenWidthToItemWidth)
      .reverse()
      .find(([screen]) => currentWidth > parseInt(screen))?.[1] || 265
  );
};
