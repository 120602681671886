import * as React from 'react';
import { Link } from 'gatsby';
import { FaArrowCircleRight } from 'react-icons/fa';

import { styled } from 'stitches.config';
import Star from '@assets/icons/star.svg';

interface GameListItemProps {
  imageUrl: string;
  title: string;
  description: string;
  rate?: number;
  href?: string;
  type: string;
}

export function GameListItem({
  imageUrl,
  title,
  description,
  rate = 5,
  href = '#',
  type,
}: GameListItemProps) {
  return (
    <Container>
      <ImageWrapper>
        <img src={imageUrl} alt={`${title} 이미지`} draggable={false} />
      </ImageWrapper>
      <InfoContainer>
        <div>{type}</div>
        <div>
          {Array.from({ length: rate }).map((_, index) => {
            return (
              <img draggable={false} key={index} src={Star} alt="별 이미지" />
            );
          })}
        </div>
      </InfoContainer>
      <Link to={href} draggable={false}>
        <InfoTitle>
          {title}
          <FaArrowCircleRight
            size={24}
            style={{ position: 'relative', top: '2px', marginLeft: '8px' }}
          />
        </InfoTitle>
      </Link>
      <InfoDescription>{description}</InfoDescription>
    </Container>
  );
}

const Container = styled('div', {
  width: '420px',

  '@bpMax1199': {
    width: '410px',
  },
  '@bpMax767': {
    width: '360px',
  },
  '@bpMax575': {
    width: '300px',
  },
  '@bpMax420': {
    width: '265px',
  },
});

const ImageWrapper = styled('div', {
  position: 'relative',
  marginBottom: '10px',
  borderRadius: '10px',
  overflow: 'hidden',
  zIndex: 0,

  img: {
    width: '100%',
  },
});

const InfoContainer = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  color: '#626262',
});

const InfoTitle = styled('div', {
  fontSize: '26px',
  fontWeight: 500,
  marginBottom: '7px',
  cursor: 'pointer',

  '&:hover': {
    color: '#c869ff',
  },
});

const InfoDescription = styled('p', {
  color: '#626262',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  marginBottom: 0,
});
