import * as React from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import { styled } from 'stitches.config';
import MainImage from '@assets/images/main_landscape.jpg';
import MainPortraitImage from '@assets/images/main_portrait.jpeg';
import BackgroundImage from '@assets/images/background-graphic.svg';
import BackgroundImage2 from '@assets/images/background-graphic-2.svg';
import HowToImage1 from '@assets/images/howto-1.svg';
import HowToImage2 from '@assets/images/howto-2.svg';
import HowToImage3 from '@assets/images/howto-3.svg';
import YellowMotif from '@assets/icons/motif-yellow.svg';
import BlueMotif from '@assets/icons/motif-blue.svg';
import PurpleMotif from '@assets/icons/motif-ppl.svg';
import { Button } from '../../Button';
import { Carousel } from '../../Carousel';
import { GameListItem } from '../../GameListItem';
import { ReviewListItem } from '../../ReviewListItem';
import {
  calculateGameItemWidth,
  calculateReviewItemWidth,
} from '@src/constants/carousel';
import { MainPageData } from '@src/templates/main';
import { Language, UISupportLanguageType } from '@src/models/language';
import { ContainerWithMediaQuery } from '@src/styles/media';
import { filterGameType } from '@src/constants/project';

interface IndexPageProps {
  data: MainPageData;
  language: { ui: UISupportLanguageType; api: Language };
}

export default function IndexPage({ data, language }: IndexPageProps) {
  const { projects, comments } = data; // 정적 생성 시 전달한 context 데이터

  // 게임 목록 캐러셀 요소 너비 계산
  const [currentGameItemWidth, setCurrentGameItemWidth] =
    React.useState<number>(
      calculateGameItemWidth(
        typeof window !== 'undefined' ? window.innerWidth : 1000
      )
    );
  // 리뷰 목록 캐러셀 요소 너비 계산
  const [currentReviewItemWidth, setCurrentReviewItemWidth] =
    React.useState<number>(
      calculateReviewItemWidth(
        typeof window !== 'undefined' ? window.innerWidth : 1000
      )
    );

  const [isPending, startTransition] = React.useTransition();

  React.useEffect(() => {
    if (isPending) return;

    const setWidth = () => {
      startTransition(() => {
        setCurrentGameItemWidth(calculateGameItemWidth(window.innerWidth));
        setCurrentReviewItemWidth(calculateReviewItemWidth(window.innerWidth));
      });
    };

    // resize 이벤트 발생 시 window.innerWidth에 따른 캐러셀 요소 너비 재계산
    window.addEventListener('resize', setWidth);

    return () => window.removeEventListener('resize', setWidth);
  }, [isPending]);

  if (language.ui === Language.KO) {
    return (
      <>
        <MainBanner>
          <Container>
            <Title>
              리얼월드를 만나면
              <br /> 세상은 <b>거대한 놀이터</b>가 되고
              <br /> 우리 모두는 <b>플레이어</b>가 됩니다.
            </Title>
            <StoreArea className="desktop">
              <a
                href="https://apps.apple.com/kr/app/realworld/id1391415522"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../../assets/images/appstore-black.png"
                  alt="앱스토어 링크"
                  placeholder="none"
                  style={{ width: '100%' }}
                />
              </a>
              <a
                href="https://play.google.com/store/apps/details?id=biz.uniquegood.realworld.sunguard&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../../assets/images/googleplay-black.png"
                  alt="구글 플레이스토어 링크"
                  placeholder="none"
                />
              </a>
            </StoreArea>
            <StoreArea className="mobile">
              <StyledAnchor
                href="https://apps.apple.com/kr/app/realworld/id1391415522"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../../assets/images/appstore-white.png"
                  alt="앱스토어 링크"
                  placeholder="none"
                  loading="eager"
                />
              </StyledAnchor>
              <StyledAnchor
                href="https://play.google.com/store/apps/details?id=biz.uniquegood.realworld.sunguard&pli=1"
                target="_blank"
                rel="noreferrer"
              >
                <StaticImage
                  src="../../../assets/images/googleplay-white.png"
                  alt="구글 플레이스토어 링크"
                  placeholder="none"
                  loading="eager"
                />
              </StyledAnchor>
            </StoreArea>
          </Container>
        </MainBanner>
        <Introduction>
          <Container>
            <FlexCenter>
              <IntroductionLeft>
                <AppImageWrapper>
                  <StaticImage
                    src="../../../assets/images/mockup-mainpage.webp"
                    alt="앱 이미지"
                  />
                </AppImageWrapper>
              </IntroductionLeft>
              <IntroductionRight>
                <IntroductionTitle>
                  <b>야외에서 즐기는 게임</b>부터
                  <br />
                  간단한 모바일 게임까지
                </IntroductionTitle>
                <IntroductionContent>
                  리얼월드는 경험형 놀이콘텐츠를 통해
                  <br />
                  놀이-여가문화의 새로운 방식을 만들어나갑니다.
                </IntroductionContent>
              </IntroductionRight>
            </FlexCenter>
          </Container>
          <BackgroundImageWrapper>
            <Image src={BackgroundImage} alt="리얼월드 로고 배경 이미지" />
          </BackgroundImageWrapper>
        </Introduction>
        <Welcome>
          <Container>
            <WelcomeTitleContainer>
              <WelcomeTitle>
                당신이 주인공이 되는
                <MobileBr /> <b>진짜 세상</b>으로 들어오세요!
              </WelcomeTitle>
              <WelcomeImageWrapper>
                <Image src={BlueMotif} alt="파란 원 이미지" />
              </WelcomeImageWrapper>
            </WelcomeTitleContainer>
          </Container>
          <Carousel
            currentWidth={currentGameItemWidth}
            style={{ marginBottom: '32px' }}
          >
            {projects.map((project) => (
              <GameListItem
                key={project.id}
                href={`/projects/${project.id}`}
                imageUrl={project.imageUrl}
                title={project.name}
                description={project.description}
                type={filterGameType(project)}
              />
            ))}
          </Carousel>
          <FlexCenter>
            <Link to="/projects">
              <Button type="outline" color="primary" height={50}>
                전체 게임 보기
              </Button>
            </Link>
          </FlexCenter>
        </Welcome>
        <HowTo>
          <HowToBackgroundImageWrapper>
            <Image src={BackgroundImage2} alt="리얼월드 로고 배경 이미지" />
          </HowToBackgroundImageWrapper>
          <Container>
            <HowToInnerContainer>
              <HowToTitle>
                <b>리얼월드</b>를 즐기고 싶다면?
              </HowToTitle>
              <HowToFlexContainer>
                <HowToFlexItem>
                  <HowToImage src={HowToImage1} alt="사용법 이미지 1" />
                  <HowToDescription>
                    리얼월드 앱을
                    <Br990 /> 다운로드 받으세요
                  </HowToDescription>
                </HowToFlexItem>
                <HowToFlexItem>
                  <HowToImage src={HowToImage2} alt="사용법 이미지 2" />
                  <HowToDescription>원하는 게임을 선택하세요</HowToDescription>
                </HowToFlexItem>
                <HowToFlexItem>
                  <HowToImage src={HowToImage3} alt="사용법 이미지 3" />
                  <HowToDescription>
                    게임의 주인공이 되어
                    <Br1200 /> 미션을 해결하세요!
                  </HowToDescription>
                </HowToFlexItem>
              </HowToFlexContainer>
            </HowToInnerContainer>
          </Container>
        </HowTo>
        <Review>
          <Container>
            <ReviewTitleWrapper>
              <YellowMotifImage src={YellowMotif} alt="노란색 이미지" />
              <ReviewTitle>
                지금 <b>{comments.count.toLocaleString()}</b>명이
                <br /> 리얼월드에서 이야기를 나누고 있어요.
              </ReviewTitle>
            </ReviewTitleWrapper>
          </Container>
          <Carousel
            currentWidth={currentReviewItemWidth}
            style={{ paddingBottom: '50px' }}
          >
            {comments.data.map((comment) => (
              <ReviewListItem
                key={comment.writerName}
                username={comment.writerName}
                rate={Math.ceil(comment.rating)}
                content={comment.content}
                game={`${comment.projectName} | ${filterGameType({
                  projectGameType: comment.projectGameType,
                  playArea: comment.playArea,
                  interactionTypeDescription:
                    comment.interactionTypeDescription,
                })}`}
                link={`/projects/${comment.projectId}`}
              />
            ))}
          </Carousel>
          <ReviewButtonContainer>
            <Link to="/projects">
              <Button type="outline" color="primary" height={50}>
                게임하고 후기 남기기
              </Button>
            </Link>
          </ReviewButtonContainer>
        </Review>
        <Video>
          <VideoSkewyBackground />
          <Container>
            <VideoTitle>
              세상은 <b>거대한 놀이터</b>이고,
              <MobileBr /> 우리 모두는 <b>플레이어</b>다.
            </VideoTitle>
            <VideoWrapper>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube-nocookie.com/embed/7IRvS6UEMLg?controls=0"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={false}
              ></iframe>
              <VideoMotifBlue src={BlueMotif} alt="파란색 원 이미지" />
              <VideoMotifPurple src={PurpleMotif} alt="보라색 이미지" />
            </VideoWrapper>
          </Container>
        </Video>
      </>
    );
  }
  return (
    <>
      <MainBanner>
        <Container>
          <Title>
            Meet the Realworld!
            <br /> All the world&apos;s a <b>stage,</b>
            <br /> and we all become <b>players!</b>
          </Title>
          <StoreArea className="desktop">
            <a
              href="https://apps.apple.com/kr/app/realworld/id1391415522"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../../assets/images/appstore-black.png"
                alt="AppStore Link"
                placeholder="none"
                style={{ width: '100%' }}
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=biz.uniquegood.realworld.sunguard&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../../assets/images/googleplay-black.png"
                alt="Google PlayStore Link"
                placeholder="none"
              />
            </a>
          </StoreArea>
          <StoreArea className="mobile">
            <StyledAnchor
              href="https://apps.apple.com/kr/app/realworld/id1391415522"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../../assets/images/appstore-white.png"
                alt="AppStore Link"
                placeholder="none"
                loading="eager"
              />
            </StyledAnchor>
            <StyledAnchor
              href="https://play.google.com/store/apps/details?id=biz.uniquegood.realworld.sunguard&pli=1"
              target="_blank"
              rel="noreferrer"
            >
              <StaticImage
                src="../../../assets/images/googleplay-white.png"
                alt="Google PlayStore Link"
                placeholder="none"
                loading="eager"
              />
            </StyledAnchor>
          </StoreArea>
        </Container>
      </MainBanner>
      <Introduction>
        <Container>
          <FlexCenter>
            <IntroductionLeft>
              <AppImageWrapper>
                <StaticImage
                  src="../../../assets/images/mockup-mainpage.webp"
                  alt="App Image"
                />
              </AppImageWrapper>
            </IntroductionLeft>
            <IntroductionRight>
              <IntroductionTitle>
                Let&apos;s start with exciting mobile games
                <br />
                Even <b>outdoor adventure!</b>
              </IntroductionTitle>
              <IntroductionContent>
                Realworld&apos;s experiential mission games
                <br />
                create a new way of play-leisure culture
              </IntroductionContent>
              <Link to="/about">
                <Button type="outline" color="primary" height={50}>
                  Introduction to the Realworld
                </Button>
              </Link>
            </IntroductionRight>
          </FlexCenter>
        </Container>
        <BackgroundImageWrapper>
          <Image src={BackgroundImage} alt="Realworld Background Image" />
        </BackgroundImageWrapper>
      </Introduction>
      <Welcome>
        <Container>
          <WelcomeTitleContainer>
            <WelcomeTitle>
              Come into the
              <MobileBr /> <b>Realworld</b> where you become the protagonist!
            </WelcomeTitle>
            <WelcomeImageWrapper>
              <Image src={BlueMotif} alt="Blue Circle Image" />
            </WelcomeImageWrapper>
          </WelcomeTitleContainer>
        </Container>
        <Carousel
          currentWidth={currentGameItemWidth}
          style={{ marginBottom: '32px' }}
        >
          {projects.map((project) => (
            <GameListItem
              key={project.id}
              href={`/projects/${project.id}`}
              imageUrl={project.imageUrl}
              title={project.name}
              description={project.description}
              type={filterGameType(project)}
            />
          ))}
        </Carousel>
        <FlexCenter>
          <Link to="/projects">
            <Button type="outline" color="primary" height={50}>
              View all games
            </Button>
          </Link>
        </FlexCenter>
      </Welcome>
      <HowTo>
        <HowToBackgroundImageWrapper>
          <Image src={BackgroundImage2} alt="Realworld Background Image" />
        </HowToBackgroundImageWrapper>
        <Container>
          <HowToInnerContainer>
            <HowToTitle>
              How to enjoy the <b>Realworld</b>
            </HowToTitle>
            <HowToFlexContainer>
              <HowToFlexItem>
                <HowToImage src={HowToImage1} alt="Howto Image 1" />
                <HowToDescription>
                  Download Realworld
                  <Br990 /> app first
                </HowToDescription>
              </HowToFlexItem>
              <HowToFlexItem>
                <HowToImage src={HowToImage2} alt="Howto Image 2" />
                <HowToDescription>Choose the game you want</HowToDescription>
              </HowToFlexItem>
              <HowToFlexItem>
                <HowToImage src={HowToImage3} alt="Howto Image 3" />
                <HowToDescription>
                  Be the hero of the game
                  <br />
                  and solve the mission!
                </HowToDescription>
              </HowToFlexItem>
            </HowToFlexContainer>
          </HowToInnerContainer>
        </Container>
      </HowTo>
      <Review>
        <Container>
          <ReviewTitleWrapper>
            <YellowMotifImage src={YellowMotif} alt="Yellow Image" />
            <ReviewTitle>
              <b>{comments.count.toLocaleString()}</b> people
              <br /> now talking in the Realworld.
            </ReviewTitle>
          </ReviewTitleWrapper>
        </Container>
        <Carousel
          currentWidth={currentReviewItemWidth}
          style={{ paddingBottom: '50px' }}
        >
          {comments.data.map((comment) => (
            <ReviewListItem
              key={comment.writerName}
              username={comment.writerName}
              rate={Math.ceil(comment.rating)}
              content={comment.content}
              game={`${comment.projectName} | ${filterGameType({
                projectGameType: comment.projectGameType,
                playArea: comment.playArea,
                interactionTypeDescription: comment.interactionTypeDescription,
              })}`}
              link={`/projects/${comment.projectId}`}
            />
          ))}
        </Carousel>
        <ReviewButtonContainer>
          <Link to="/projects">
            <Button type="outline" color="primary" height={50}>
              Playing games and leaving a review
            </Button>
          </Link>
        </ReviewButtonContainer>
      </Review>
      <Video>
        <VideoSkewyBackground />
        <Container>
          <VideoTitle>
            All the world&apos;s a <b>stage</b>,
            <MobileBr /> and we all become <b>players!</b>
          </VideoTitle>
          <VideoWrapper>
            <iframe
              width="560"
              height="315"
              src="https://www.youtube-nocookie.com/embed/7IRvS6UEMLg?controls=0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen={false}
            ></iframe>
            <VideoMotifBlue src={BlueMotif} alt="Blue Image" />
            <VideoMotifPurple src={PurpleMotif} alt="Purple Image" />
          </VideoWrapper>
        </Container>
      </Video>
    </>
  );
}

const MainBanner = styled('div', {
  display: 'flex',
  alignItems: 'center',
  height: '80vh',
  backgroundImage: `url(${MainImage})`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',

  '@bpMax767': {
    backgroundImage: `url(${MainPortraitImage})`,
  },
});

const Container = styled(ContainerWithMediaQuery, {
  width: '100%',
  padding: '0 1.5rem',
  margin: '0 auto',
});

const Title = styled('div', {
  marginBottom: '40px',
  color: '#141414',
  fontSize: '2.5rem',
  fontWeight: '500',

  '@bpMax767': {
    color: '#fff',
    textShadow: '0 0 5px rgba(0, 0, 0, 0.2)',
  },
  '@bpMax575': {
    fontSize: '24px',
    textAlign: 'center',
    lineHeight: 1.5,
  },
});

const Image = styled('img', {
  width: '100%',
  height: '100%',
});

const StoreArea = styled('div', {
  display: 'none',

  '&.desktop': {
    '@media (min-width: 768px)': {
      display: 'flex',
    },
  },
  '&.mobile': {
    '@media (max-width: 767px)': {
      display: 'flex',
    },
  },

  '& a:not(:last-child)': {
    marginRight: '15px',
  },

  '@bpMax575': {
    justifyContent: 'center',
  },
});

const StyledAnchor = styled('a', {
  '@bpMax575': {
    margin: '0 5px 10px',
    fontSize: 18,
    flexShrink: 1,
  },
});

const BackgroundImageWrapper = styled('div', {
  position: 'absolute',
  right: '-100px',
  bottom: '-200px',
  zIndex: -1,

  '@bpMax991': {
    right: '-300px',
  },
  '@bpMax575': {
    top: '-300px',
    right: '-260px',
  },
});

const FlexCenter = styled('div', {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  '@bpMax767': {
    flexDirection: 'column',
  },
});

const Introduction = styled('div', {
  position: 'relative',
  height: '720px',
  overflow: 'hidden',

  '@bpMax767': {
    height: '920px',
  },
  '@bpMax420': {
    height: 'auto',
    paddingBottom: '60px',
  },
});

const IntroductionLeft = styled('div', {
  paddingTop: '120px',
  overflow: 'hidden',
  flexShrink: 1,

  '@bpMax767': {
    height: '590px',
    paddingTop: '80px',
    marginBottom: '20px',
  },
  '@bpMax420': {
    height: '470px',
  },
});

const AppImageWrapper = styled('div', {
  display: 'block',
  position: 'relative',
  width: '80%',
  maxWidth: '415px',

  '@bpMax767': {
    margin: '0 auto',
  },
});

const IntroductionRight = styled('div', {
  flexShrink: 1,

  '@bpMax767': {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
  },
});

const IntroductionTitle = styled('h2', {
  marginBottom: '20px',
  fontSize: '2rem',

  '@bpMax420': {
    fontSize: '25px',
  },
});

const IntroductionContent = styled('p', {
  marginBottom: '30px',
  fontSize: '18px',
  color: '#626262',
});

const Welcome = styled('div', {
  padding: '100px 0',
  backgroundColor: '#fafafa',

  '@bpMax420': {
    padding: '60px 0',
  },
});

const WelcomeTitleContainer = styled('div', {
  position: 'relative',

  '@bpMax767': {
    textAlign: 'center',
  },
});

const WelcomeTitle = styled('h2', {
  marginBottom: '20px',
  fontSize: '2rem',

  '@bpMax420': {
    fontSize: '25px',
    fontWeight: 400,
  },
});

const MobileBr = styled('br', {
  display: 'none',

  '@bpMax767': {
    display: 'inline',
  },
});

const WelcomeImageWrapper = styled('div', {
  position: 'absolute',
  top: '-30px',
  left: '-60px',
  width: '180px',
});

const HowTo = styled('div', {
  position: 'relative',
  padding: '100px 0',
  overflow: 'hidden',

  '@bpMax420': {
    padding: '60px 0',
  },
});

const HowToInnerContainer = styled('div', {
  textAlign: 'center',
});

const HowToBackgroundImageWrapper = styled('div', {
  position: 'absolute',
  top: '-230px',
  right: '-180px',
  width: '670px',
  height: 'fit-content',
  zIndex: -1,

  '@bpMax420': {
    bottom: '-230px',
    right: 'auto',
  },
});

const HowToTitle = styled('h2', {
  marginBottom: '40px',
  fontSize: '2rem',

  '@bpMax420': {
    fontSize: '25px',
    fontWeight: 400,
    lineHeight: 1.5,
  },
});

const HowToFlexContainer = styled('div', {
  display: 'flex',

  '@bpMax767': {
    flexDirection: 'column',
  },
});

const HowToFlexItem = styled('div', {
  margin: '0 20px',
  flexShrink: 1,

  '@bpMax767': {
    marginBottom: '20px',
  },
});

const HowToImage = styled('img', {
  maxWidth: '100%',

  '@bpMax1199': {
    height: '270px',
  },
  '@bpMax991': {
    height: '200px',
  },
  '@bpMax767': {
    height: '400px',
  },
  '@bpMax575': {
    height: '300px',
  },
  '@bpMax420': {
    height: '200px',
  },
});

const HowToDescription = styled('p', {
  marginTop: '20px',
  fontSize: '19px',
  textAlign: 'center',
});

const Br1200 = styled('br', {
  display: 'none',

  '@bpMax1199': {
    display: 'inline',
  },
  '@bpMax767': {
    display: 'none',
  },
});

const Br990 = styled('br', {
  display: 'inline',

  '@bpMax767': {
    display: 'none',
  },
});

const Review = styled('div', {
  paddingTop: '100px',
  backgroundColor: '#fafafa',
});

const ReviewTitleWrapper = styled('div', {
  position: 'relative',
  '@bpMax767': {
    textAlign: 'center',
  },
});

const ReviewTitle = styled('h2', {
  position: 'relative',
  marginBottom: '50px',
  fontSize: '2rem',
  zIndex: 1,
});

const YellowMotifImage = styled('img', {
  position: 'absolute',
  top: '-30px',
  left: '-50px',

  '@bpMax767': {
    left: 0,
  },
});

const ReviewButtonContainer = styled('div', {
  display: 'flex',
  justifyContent: 'center',
});

const Video = styled('div', {
  position: 'relative',
  paddingTop: '200px',
  paddingBottom: '150px',
  overflow: 'hidden',
});

const VideoSkewyBackground = styled('div', {
  position: 'absolute',
  bottom: '500px',
  width: '100%',
  height: '800px',
  backgroundColor: '#fafafa',
  transform: 'skewy(10deg)',
  zIndex: -1,
});

const VideoWrapper = styled('div', {
  position: 'relative',
  paddingBottom: '44.79%',

  '& iframe': {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 2,
  },
});

const VideoTitle = styled('h2', {
  marginBottom: '30px',
  fontSize: '2rem',
  textAlign: 'center',

  '@bpMax420': {
    fontSize: '25px',
    fontWeight: 400,
  },
});

const VideoMotifBlue = styled('img', {
  position: 'absolute',
  left: '-10vw',
  bottom: '-5vw',
  width: '30vw',
});

const VideoMotifPurple = styled('img', {
  position: 'absolute',
  top: '-10vw',
  right: '-15vw',
  width: '20vw',
});
