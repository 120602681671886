import * as React from 'react';
import { PageProps } from 'gatsby';

import MainPage from '@src/components/pages/Main';
import { GeneralHead } from '@src/components/GeneralHead';
import { Language, UISupportLanguageList } from '@src/models/language';
import { NavigationBar } from '@src/components/NavigationBar';
import { Footer } from '@src/components/Footer';
import { Project } from '@src/models/project';
import { PlayComment } from '@src/models/playComment';
import { Layout } from '@src/components/Layout';
import { initLanguage } from '@src/utils/translation';
import { useQueryString } from '@src/hooks/useQueryString';

export interface MainPageData {
  projects: Project[];
  comments: {
    count: number;
    data: PlayComment[];
  };
}

interface IndexProps {
  pageContext: PageProps<unknown, MainPageData>['pageContext'];
}

/**
 * 정적 생성을 위한 컴포넌트
 * 레이아웃 컴포넌트와 실제 메인 페이지 컴포넌트를 렌더링
 * @param data gatsby-node.ts에서 전달한 context, 메인 페이지에 필요한 데이터
 */
export default function Index({ pageContext: data }: IndexProps) {
  const { isPreparing } = useQueryString();

  const [browserLocale, setBrowserLocale] = React.useState<
    Language | undefined
  >();

  React.useLayoutEffect(() => {
    const isClientSide = typeof window !== 'undefined';

    if (isClientSide) {
      const languageCode = navigator.language.split('-')[0].toLowerCase();
      const browserLocale =
        Object.values(Language).find((language) => language === languageCode) ||
        Language.EN;

      setBrowserLocale(browserLocale);
    }

    initLanguage();
  }, []);

  return (
    <>
      {!isPreparing &&
        Object.values(Language).map((language) => {
          const uiLanguage = UISupportLanguageList.find((i) => i === language);

          return (
            <Layout
              key={language}
              header={({ scrollElement }) => (
                <NavigationBar scrollRef={scrollElement} />
              )}
              footer={<Footer />}
              style={{
                defaultLayout: {
                  visibility: !browserLocale ? 'hidden' : 'visible',
                  display:
                    !browserLocale || browserLocale === language ? '' : 'none',
                },
              }}
              floatingButton={true}
            >
              <MainPage
                data={data}
                language={{
                  ui: uiLanguage ?? Language.EN,
                  api: language,
                }}
              />
            </Layout>
          );
        })}
    </>
  );
}

export const Head = () => {
  return (
    <>
      <GeneralHead />
      <title>모두의 놀이터, 리얼월드 - 리얼월드</title>
    </>
  );
};
