import * as React from 'react';

import { styled } from 'stitches.config';
import { useInterval } from '@src/hooks/useInterval';
import { ContainerWithMediaQuery } from '@src/styles/media';

interface CarouselProps {
  children: React.ReactNode;
  transitionDuration?: number;
  movingDelay?: number;
  currentWidth: number;
  style?: React.CSSProperties;
}

/**
 * 캐러셀 컴포넌트
 * @param children 자식 요소
 * @param transitionDuration 캐러셀 요소가 이동하는 시간
 * @param movingDelay 캐러셀 요소가 한 번 이동하고 다음 이동까지의 딜레이
 * @param currentWidth 캐러셀 요소가 반응형일 경우, 화면 크기에 따른 요소의 너비
 * @param style CSS 스타일
 */
export function Carousel({
  children,
  transitionDuration = 400,
  movingDelay = 2000,
  currentWidth,
  style,
}: CarouselProps) {
  const childrenArray = React.Children.toArray(children);

  const nodes = [...childrenArray, ...childrenArray, ...childrenArray];

  const [currentSlide, setCurrentSlide] = React.useState<number>(
    childrenArray.length
  );
  const [hasTransition, setHasTransition] = React.useState(true);
  const [isDragging, setIsDragging] = React.useState(false);

  const movingRef = React.useRef<HTMLDivElement | null>(null);
  const posX = React.useRef(0);
  const difference = React.useRef(0);

  /**
   * transitionDuration 이후에 hasTransition을 false로 변경해 transition을 없애고
   * 원하는 index로 바로 이동시킴.
   * 무한 캐러셀을 구현하기 위해 맨 처음 요소에서 맨 마지막 요소로 이동하거나, 맨 처음 요소 -> 맨 마지막 요소로 이동할 때 사용.
   * @param index 이동하고자 하는 슬라이드의 인덱스
   */
  const moveToSlide = (index: number) => {
    setTimeout(() => {
      setHasTransition(false);
      setCurrentSlide(index);
    }, transitionDuration);
  };

  /**
   * currentSlide를 1 증가시킨다.
   * 만약 마지막 슬라이드에서 실행했다면 맨 처음 요소로 transition 없이 이동시킨다.
   */
  const nextSlide = () => {
    const next = currentSlide + 1;
    setCurrentSlide(next);

    if (next === childrenArray.length * 2) {
      moveToSlide(childrenArray.length);
    }
    setHasTransition(true);
  };

  useInterval(
    () => {
      nextSlide();
    },
    isDragging ? null : movingDelay
  );

  /**
   * 이벤트 객체가 마우스 이벤트일 경우 마우스의 X 좌표를,
   * 터치 이벤트일 경우 첫번째 터치의 X 좌표를 posX.current 에 저장하고 document에 mouseup, touchend 이벤트를 달아 클릭 또는 터치가 끝났을 때 실행할 이벤트를 달아줌.
   * @param e 이벤트 객체 (마우스 이벤트 또는 터치 이벤트)
   */
  const handleDragStart = (e: React.MouseEvent | React.TouchEvent) => {
    setIsDragging(true);

    if (e.nativeEvent instanceof MouseEvent) {
      posX.current = e.nativeEvent.clientX;

      document.addEventListener('mouseup', handleDragEnd);
    } else if (e.nativeEvent instanceof TouchEvent) {
      posX.current = e.nativeEvent.touches[0].clientX;

      document.addEventListener('touchend', handleDragEnd);
    }
  };

  /**
   * X 좌표를 currentPos에 저장하고, 시작 좌표와 비교해서 차이만큼 movingRef의 left 값을 변경해줌.
   * 터치 이벤트일 경우 캐러셀 이동과 화면 스크롤이 동시에 일어나는 걸 방지하기 위해 시작 위치와 현재 위치의 X 값의 차이가 100 이상이면 스크롤을 막아둠.
   * @param e 이벤트 객체 (마우스 이벤트 또는 터치 이벤트)
   */
  const handleDragging = (e: React.MouseEvent | React.TouchEvent) => {
    if (!isDragging) return;

    let currentPos = 0;
    if (e.nativeEvent instanceof MouseEvent) {
      currentPos = e.nativeEvent.clientX;
    } else if (e.nativeEvent instanceof TouchEvent) {
      currentPos = e.nativeEvent.touches[0].clientX;

      if (Math.abs(currentPos - posX.current) > 100) {
        document.body.style.overflow = 'hidden';
      }
    }

    difference.current = currentPos - posX.current;

    if (movingRef.current) {
      movingRef.current.style.left = `${difference.current}px`;
    }
  };

  /**
   * 시작 위치와 현재 위치의 차이를 currentWidth 값으로 나눠 캐러셀 요소 몇 칸을 이동해야 할 지 계산.
   * 현재 인덱스에서 계산한 인덱스를 뺀 값을 현재 슬라이드로 설정
   */
  const handleDragEnd = () => {
    setIsDragging(false);
    document.body.style.overflow = '';

    const calculatedIndex = Math.round(difference.current / currentWidth);
    if (calculatedIndex !== 0) {
      const nextIndex = currentSlide - calculatedIndex;

      setHasTransition(false);
      setCurrentSlide(nextIndex);

      if (nextIndex < childrenArray.length) {
        setCurrentSlide((prev) => prev + childrenArray.length);
      } else if (nextIndex >= childrenArray.length * 2) {
        setCurrentSlide((prev) => prev - childrenArray.length);
      }
    }

    posX.current = 0;
    difference.current = 0;
    if (movingRef.current) {
      movingRef.current.style.left = '';
    }

    document.removeEventListener('mouseup', handleDragEnd);
    document.removeEventListener('touchend', handleDragEnd);
  };

  React.useLayoutEffect(() => {
    if (movingRef.current) {
      movingRef.current.style.transform = `translate3d(-${
        (currentWidth + 20) * currentSlide
      }px, 0, 0)`;
    }
  }, [currentSlide, currentWidth]);

  return (
    <List style={style}>
      <Container>
        <MovingContainer
          onMouseDown={handleDragStart}
          onMouseMove={handleDragging}
          onTouchStart={handleDragStart}
          onTouchMove={handleDragging}
          ref={movingRef}
          style={{
            transform: `translate3d(-${
              (currentWidth + 20) * currentSlide
            }px, 0, 0)`,
          }}
          hasTransition={hasTransition}
        >
          {nodes.map((item, index) => {
            if (React.isValidElement(item)) {
              return React.cloneElement(item, { key: index });
            }
          })}
        </MovingContainer>
      </Container>
    </List>
  );
}

const List = styled('div', {
  overflowX: 'hidden',
});

const Container = styled(ContainerWithMediaQuery, {
  width: '100%',
  margin: '0 auto',
  padding: '0 1.5rem',
});

const MovingContainer = styled('div', {
  position: 'relative',
  display: 'flex',

  variants: {
    hasTransition: {
      true: {
        transition: `transform 400ms ease-out`,
      },
      false: {
        transition: 'none',
      },
    },
  },

  '& > div:not(:last-child)': {
    marginRight: '20px',
  },
});
